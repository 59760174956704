export default {
    logo: 'StayRichAtHome',
    nav:{
        home: 'AccueilLe',
        unsubscribe: 'Mon abonnement',
    },
    mailSent:'Votre mail a été envoyé.',
    getInTouch:{
        title: 'ENTRER EN CONTACT',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        email: 'E-mail',
        msg: 'Message',
        send: 'Envoyer',
        cancelYourSubscription: 'ANNULER VOTRE ABONNEMENT',
        inAOneClick: 'EN UN CLIC AVEC',
        ourOnlineCancel: 'NOTRE SERVICE D\'ANNULATION EN LIGNE',
        text1: 'Nos opérateurs connaissent toutes les réponses et vous donneront des conseils efficaces et utiles dans votre cas.',
        text2: 'Nos services d\'abonnement vous offrent la solution de toute la différence de vos problèmes avec nos programmes de coaching uniques, d\'assistance au client et d\'expertise qualifiée. Nous poursuivons un seul objectif: rendre votre vie radieuse et légère.',
        desc1: 'Experts rapides et efficaces',
        desc2: 'Partenaires professionnels',
        desc3: 'Spécialiste des services d\'affinité',
        desc4: '100% gratuit, pas de données bancaires',
    },
    questions:{
        title1: 'SERVICE CLIENT POUR TOUS VOS ABONNEMENTS EN LIGNE',
        desc1: 'Gestion facile de vos abonnements. Nous vous proposons une gestion pratique des différents abonnements. Remplissez le formulaire avec l\'adresse e-mail que vous avez utilisée lors de votre inscription et vous êtes dans votre compte client en un clic. Toutes les informations sont là pour rendre votre gestion confortable et vous permettre de contrôler au maximum vos abonnements. Vous pouvez également nous contacter pour demander de l\'aide, vous désabonner ou déposer une plainte. Tout est préparé pour que vous puissiez gérer tous les abonnements de la manière la plus simple possible.',
        title2: 'DES AGENTS À VOTRE SERVICE',
        desc2: 'Nos agents sont des experts du service après-vente et ont toutes les solutions à vos problèmes. Ils travaillent 24h / 24 et 7j / 7. Vous pouvez donc contacter les agents par chat en direct ou par téléphone via notre numéro sans frais à tout moment. Vous pouvez également leur envoyer un email auquel ils répondront dans les 48 heures. Si vous avez des problèmes pour gérer votre abonnement sur les ports ou si vous avez une autre question, contactez-nous.',
        faq: 'questions fréquentes',
        faqList:[
            {
                id:1,
                show: false,
                question: 'Quels services proposez-vous?',
                answer: 'Notre service vous offre le moyen le plus simple de gérer votre abonnement. Il vous suffit d\'accéder à votre compte client et de remplir le formulaire avec une adresse e-mail. Nos agents vous contacteront et vous pourrez obtenir toutes les informations concernant votre abonnement ou l\'annuler.'
            },
            {
                id:2,
                show: false,
                question: 'Je ne me souviens pas de votre inscription à votre abonnement.',
                answer: 'Vous n\'êtes pas inscrit à notre abonnement. Vous avez probablement souscrit un abonnement sur l\'un de nos sites partenaires pour lequel nous fournissons un service après-vente. C\'est pourquoi vous avez les prélèvements sur votre relevé bancaire.'
            },
            {
                id:3,
                show: false,
                question: 'Comment puis-je trouver l\'offre choisie?',
                answer: 'Si vous souhaitez connaître les détails de votre offre, entrez simplement votre adresse e-mail d\'inscription dans le formulaire de la page d\'accueil. Notre agent vous contactera ensuite pour vous fournir les informations qui vous intéressent au sujet de votre abonnement.'
            },
            {
                id:4,
                show: false,
                question: 'J\'ai oublié mon adresse email enregistrée? Qu\'est-ce que je peux faire?',
                answer: 'Nos agents vous aideront avec ce problème. Ils trouveront votre fichier, il vous suffira de les contacter par email, téléphone ou chat.'
            },
            {
                id:5,
                show: false,
                question: 'J\'ai une question, avec qui puis-je contacter?',
                answer: 'Notre équipe amicale travaille 24 heures sur 24, 7 jours sur 7, vous pouvez donc nous contacter à tout moment par chat ou par téléphone Vous pouvez également nous envoyer le courrier électronique avec votre question et nous vous répondrons dans les 48 heures.'
            },
            {
                id:6,
                show: false,
                question: 'Comment puis-je annuler mon abonnement?',
                answer: 'Si vous souhaitez annuler votre abonnement, vous devez accéder à votre compte client et cliquer sur le bouton "Désabonnement". Mais vous ne bénéficierez plus des services de notre site et ne serez plus facturé par les portails. Un email de confirmation vous sera envoyé.'
            },
            {
                id:7,
                show: false,
                question: 'J\'ai quelques difficultés à utiliser l\'espace membre, que dois-je faire?',
                answer: 'Si vos difficultés sont liées à l\'utilisation de votre compte personnel, veuillez contacter notre support technique. Ils vous aideront à résoudre le problème qui vous concerne.'
            },
            {
                id:8,
                show: false,
                question: 'Je ne reconnais pas la transaction sur mon relevé bancaire?',
                answer: 'Si yogamoodon.com est mentionné sur votre relevé bancaire ou sur votre relevé de carte de crédit, cela signifie que nous vous avons facturé pour le compte d\'un commerçant en ligne et que notre service clientèle est à votre disposition pour vous aider à résoudre votre problème et répondre à votre demande. Vous aurez besoin d\'au moins deux des informations suivantes: carte de crédit, adresse électronique ou référence du membre.'
            },
            {
                id:9,
                show: false,
                question: 'Je veux annuler mon inscription? Comment puis-je faire ceci?',
                answer: 'Vous avez deux façons de le faire. Vous pouvez le faire vous-même dans votre compte personnel ou contacter notre service. Pour cette opération, nous aurons besoin de certaines informations: nom d\'utilisateur, carte de crédit, adresse électronique ou référence du membre.'
            },
            {
                id:10,
                show: false,
                question: 'J\'ai annulé mon inscription mais j\'ai toujours accès à la zone membres',
                answer: 'Nous vous enverrons toujours un email de confirmation après votre annulation. Cela garantit que nous ne vous facturerons pas pour le prochain cycle de facturation. Si vous avez reçu le courrier électronique d\'annulation, il est certain que vous ne serez pas facturé à nouveau. Cependant, vous aurez toujours accès à l\'espace membre jusqu\'à la fin de la période que vous avez payée.'
            },
            {
                id:11,
                show: false,
                question: 'Puis-je envoyer mon numéro de carte de crédit par courrier électronique?',
                answer: 'N\'envoyez jamais votre numéro de carte de crédit par courrier électronique. Ce sont des informations confidentielles. Utilisez toujours nos formulaires en ligne pour votre propre sécurité. Si, pour une raison quelconque, vous ne pouvez pas le faire, appelez notre service clientèle.'
            },
            {
                id:12,
                show: false,
                question: 'Quel est le code CVV et où puis-je le trouver?',
                answer: 'Il s’agit d’un code de sécurité supplémentaire destiné à vous protéger d’une fraude éventuelle. Il est imprimé au verso de votre carte. Il se compose des 3 derniers chiffres qui suivent le nom de votre compte au dos de votre carte de crédit.'
            },
            {
                id:13,
                show: false,
                question: 'J\'ai oublié l\'adresse Web du site que j\'ai acheté. Comment puis-je le récupérer?',
                answer: 'Pour recevoir l\'adresse Web par courrier électronique: - Si vous disposez d\'au moins deux des détails suivants: Nom d\'utilisateur, Carte de crédit, Adresse électronique ou Référence du membre.'
            },
            {
                id:14,
                show: false,
                question: 'J\'ai une question à laquelle la FAQ ne répond pas. Que puis-je faire?',
                answer: 'Si vous avez des problèmes ou des questions, nous serons heureux de vous entendre et de vous fournir une assistance qualifiée. Notre équipe travaille pour vous et s\'efforce de vous fournir des services de qualité supérieure. N\'hésitez pas à nous contacter!'
            },
            {
                id:15,
                show: false,
                question: 'Je ne parviens pas à me connecter à mon compte. Le nom d\'utilisateur et le mot de passe ne fonctionnent pas. Que devrais-je faire?',
                answer: 'Assurez-vous que vous avez entré vos coordonnées exactement comme indiqué dans l\'e-mail de confirmation. Pour recevoir l\'adresse Web par courrier électronique, nous avons besoin de votre nom d\'utilisateur, de votre carte de crédit, de votre courrier électronique ou de votre référence de membre.'
            },
        ],
        title3: 'NOUS SOMMES ICI POUR AMÉLIORER VOTRE VIE',
        subTitle3: 'reste avec nous et utilise',
        desc3: 'support 24/7, nos experts savent ce que vous recherchez',
        desc4: 'réponse rapide et solution de votre demande dans les 12 heures',
        desc5: 'service absolument gratuit',
        desc6: 'les solutions proposées par nos experts sont toujours claires, simples et rapides',
    },
    cancellation:{
        title1: 'DEMANDE D\'ANNULATIONT',
        titleForm1: 'Adresse e-mail utilisée lors de l\'achat',
        info1: 'Nous ne partagerons ces informations avec aucun tiers.',
        text1: 'En cochant cette case, j\'autorise la désinscription et la suppression de mon compte de votre base de données. Cette action mettra fin à toute facturation ultérieure',
        address1: 'Amadora, Portugal',
        errorEmail: 'Entrez une adresse email valide',
        workTime: 'Centre d\'appels Fonctionne de 10h à 17h',
    }
}
